import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Modal,
  Paper,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { getApiDataProgressPayment, liveUrl } from '../APIHelper'
import { isArray, isEmpty, isUndefined } from 'lodash'
import CardDetails from '../CardDetails'
import PaymentStatus from '../PaymentStatus'
import { loadStripe } from '@stripe/stripe-js'
import useStyles from './styles'
import { Elements } from '@stripe/react-stripe-js'
import { useTheme } from '@emotion/react'
import { Strings } from '../../Strings'
import { capitalizeWordFirstLetter, checkNull } from '../../Helpers'
import CryptoJS from 'crypto-js'
import moment from 'moment'
import { currency_symbol } from '../staticData'
import PricingBreakdown from '../PricingBreakdown'

/**
 * PaymentAggregator component for handling various payment methods and aggregating them.
 *
 * @component
 * @param {Object} props - Component properties.
 * @returns {JSX.Element} - React JSX element representing the PaymentAggregator component.
 */
const PaymentAggregator = (props) => {
  const [paySuccess, setPaySuccess] = useState(false)
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down('sm'))
  const lg = useMediaQuery(theme.breakpoints.down('lg'))
  const currentDomain = window.location

  const {
    PaymentType = '', //require => one_time, subscription, custom_subscription
    paymentData = {
      name: 'Ronak',
      amount: 50,
      email: '',
      country_code: 'IN',
      app_token:
        'app_5B7768DA83B0AE019F437C0CCD61C5EE52A9ED60F3A566EBE1F286009B61',
      currency: 'INR',
      transaction_code: 'txn_1234567',
      mobile: '8008996001',
      payThroughEMI: false,
      payThroughRecurrence: false,
      updateSubscription: false, //for update subscription like cancel,pause,resume,update payment Method,
      //for razorpay there is no update payment method
      updateSubscriptionType: 'paymentMethod', //cancel,pause,resume,paymentMethod (required if updateSubscription=true)
      recurrenceObj: {
        intervalType: 'monthly', //weekly,monthly,yearly
        //default is 1
        interval_count: 1, //if interval_count=2 then it is for every 2 (months,week,year)
        //default is 0 means forever
        iterations: 0, //if suppose iteration is 2 then it will charge 2 (months,week,year)
        start_at: '', //in local date time format for future subscription schedule
        order_code: '08D2665B0B8B0BB104845056E7B12911F29573259DE8156338', //required for update payment method
      },
    }, //require
    onPaymentDone = () => {
      // setTimeout(() => setPaySuccess(false), 3000)
    },
    onCloseClick = () => {},
    appCharges = [
      {
        id: 1,
        name: 'Platform Fees',
        slug: 'platform_fees',
        type: 'exclude' === 'exclude' ? 'exclusive' : 'inclusive',
        value: 2,
      },
      // {
      //   id: 2,
      //   name: 'Payment Gateway Fee',
      //   slug: 'payment_gateway_fee',
      //   type: 'exclude' === 'exclude' ? 'exclusive' : 'inclusive',
      //   value: 0,
      // },
    ],
    noCharge = false,
    pluginURL = 'staging', //staging, dev, prodapi
    changeBtnText = 'Pay',
    showHeader = true,
    primaryColor = '#0068EF',

    // For gradient
    isGradientButton = true,
    linearColorsName = ['#0BBCED', '#1252A5'],
    themeColor = '#F5F9FF',
  } = props

  const styles = useStyles()

  const stripePromise = loadStripe(
    'pk_test_51Lp74WLvsFbqn13LVwHWLWuHOMzx3Jyn8dZSAVjGf9oIetpNOgvbbMMRjp5WRRheejXuSftYmD9uoebv2y0Rdm1h003RC3YCS6'
  )
  const paymentId = useRef(null)

  // const liveUrl = "https://aautidev.aautipay.com/plugin/"; //dev,staging
  // // const liveUrl = 'https://aautipayprodapi.aautipay.com/plugin/' //demo,prod
  // // const liveUrl = 'http://10.30.2.81:3000/plugin/'

  const [failMessage, setFailMessage] = useState('')

  const [pageLoade, setPageLoader] = useState(true)
  const [chargeData, setChargeData] = useState({})

  const [activeIndex, setActiveIndex] = useState([])
  const [paymentMethod, setPaymentMethod] = useState([])
  const [achData, setAchData] = useState({
    redirect_ach_url: '',
    trans_code: '',
    payType: '',
  })

  const isCard = paymentMethod?.filter(
    (item) => item?.type === 'card' && !isEmpty(item?.charge_object)
  )
  const isBank = paymentMethod?.filter(
    (item) => item?.type === 'bank' && !isEmpty(item?.charge_object)
  )
  const isOther = paymentMethod?.filter(
    (item) =>
      item?.type === 'digital wallet' &&
      !isEmpty(item?.charge_object) &&
      item['payment_method.payment_method'] !== 'Apple Pay' &&
      item['payment_method.payment_method'] !== 'Google Pay'
  )

  const [paymentSelectedType, setPaymentSelectedType] = useState('')

  const isRecurring =
    paymentData?.payThroughRecurrence ||
    paymentData?.payThroughEMI ||
    paymentData?.updateSubscription

  const isForUpdatePayDetails = paymentData?.updateSubscription
    ? [Strings.cancel, Strings.pause, Strings.resume]?.includes(
        paymentData?.updateSubscriptionType
      )
      ? true
      : false
    : false

  const isFromRazorpay = paymentData?.country_code === 'IN' ? true : false

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement('script')
      script.src = src
      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }
      document.body.appendChild(script)
    })
  }

  const loadRazorpay = async (amount, id) => {
    let { email, mobile, name } = paymentData
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')
    let msg = ''
    if (!res) {
      console.log('Razorpay SDK failed to load. Are you online?')
      setPaySuccess('fail')
      setFailMessage('Authentication Failed')
    }
    const options = {
      key: 'rzp_test_Cs1KAEs9gfxL5a', // Replace with your Razorpay key
      amount: amount * 100, // Amount is in paise, so this is 50000 paise or ₹500
      currency: 'INR',
      handler: function (response) {
        setPaySuccess(msg)
        onPaymentDone()
        // You can send the payment ID to your server for verification here
      },
      modal: {
        confirm_close: true, // this is set to true, if we want confirmation when clicked on cross button.
        // This function is executed when checkout modal is closed
        // There can be 3 reasons when this modal is closed.
        ondismiss: async (reason) => {
          let status =
            !paymentData?.payThroughRecurrence &&
            !paymentData?.updateSubscription
              ? 'fail'
              : paymentData?.updateSubscription
              ? Strings.updateFailed
              : 'Subscription Pending'
          // Reason 1 - when payment is cancelled. It can happend when we click cross icon or cancel any payment explicitly.
          if (reason === undefined) {
            setPaySuccess(status)
            setFailMessage('Payment processing cancelled by user')
            if (!paymentData?.updateSubscription) {
              setTimeout(() => {
                setPaySuccess(false)
              }, 5000)
            }
          }
          // Reason 2 - When modal is auto closed because of time out
          else if (reason === 'timeout') {
            setPaySuccess(status)
            setFailMessage(reason)
            if (!paymentData?.updateSubscription) {
              setTimeout(() => {
                setPaySuccess(false)
              }, 5000)
            }
          }
          // Reason 3 - When payment gets failed.
          else {
            setPaySuccess(status)
            setFailMessage(reason?.error?.description)
            if (!paymentData?.updateSubscription) {
              setTimeout(() => {
                setPaySuccess(false)
              }, 5000)
            }
          }
        },
      },
      prefill: {
        email: email,
        contact: mobile,
        name: name,
      },
      retry: {
        enabled: true,
      },
    }
    if (
      !paymentData?.payThroughRecurrence &&
      !paymentData?.updateSubscription
    ) {
      options.order_id = id
      msg = 'success'
    } else if (
      paymentData?.payThroughRecurrence &&
      !paymentData?.updateSubscription
    ) {
      options.subscription_id = id
      msg = Strings.subscriptionSuccess
    } else if (
      paymentData?.updateSubscription &&
      paymentData?.updateSubscriptionType === 'paymentMethod'
    ) {
      options.subscription_id = id
      options.subscription_card_change = true
      msg = Strings.updatePayMethod
    }
    // All information is loaded in options which we will discuss later.
    const rzp1 = new window.Razorpay(options)

    // If you want to retreive the chosen payment method.
    rzp1.on('payment.submit', (response) => {
      paymentMethod.current = response.method
    })

    // To get payment id in case of failed transaction.
    rzp1.on('payment.failed', (response) => {
      paymentId.current = response.error.metadata.payment_id
    })

    // to open razorpay checkout modal.
    rzp1.open()
  }

  const changePayMethod = async () => {
    if (
      paymentData?.updateSubscription &&
      isFromRazorpay &&
      paymentData?.updateSubscriptionType === Strings.paymentMethod
    ) {
      const getDetails = await checkPaymentProgress(
        paymentData?.recurrenceObj?.order_code,
        true
      )
      if (!checkNull(getDetails) && Object.keys(getDetails)?.length > 0) {
        loadRazorpay(getDetails?.charged_amount, getDetails?.subscription_id)
      } else {
        setPaySuccess('fail')
        setFailMessage('Authentication failed')
      }
    }
  }

  useEffect(() => {
    getAccessToken()
    setFailMessage('')
  }, [props])

  useEffect(() => {
    isEmpty(paymentSelectedType) &&
      setPaymentSelectedType(
        !isEmpty(isOther)
          ? 'digital wallet'
          : !isEmpty(isBank)
          ? 'bank'
          : 'card'
      )
  }, [isOther, isCard])

  const iframe = document.getElementById('myIframe')
  useEffect(() => {
    if (iframe) {
      // Listen for the iframe's load event
      iframe.addEventListener('load', function () {
        // Access the iframe's contentWindow and its location object
        const iframeContentWindow = iframe.contentWindow
        const url = iframeContentWindow.location.href
        var regex = /[?&]([^=#]+)=([^&#]*)/g,
          params = {},
          match
        while (!isEmpty(url) && (match = regex.exec(url))) {
          params[match[1]] = match[2]
        }
        if (url.includes('payment_intent')) {
          checkStripePayment(url)
        } else if (url.includes('success')) {
          setPaySuccess('success')
          onPaymentDone()
          // Close the InAppBrowser
        } else if (url.includes('failure')) {
          setPaySuccess('fail')
          let msg = params?.message ?? ''
          // let message = msg?.replace(/%20/g, ' ')
          let message = decodeURIComponent(decodeURIComponent(msg))
          setFailMessage(message ?? 'Authentication failed')
          setTimeout(() => {
            setPaySuccess(false)
          }, 5000)
        } else if (url.includes('cancel')) {
          setPaySuccess('cancel')
          let msg = params?.message ?? ''
          // let message = msg?.replace(/%20/g, ' ')
          let message = decodeURIComponent(decodeURIComponent(msg))
          setFailMessage(message ?? 'Payment Cancelled')
          setTimeout(() => {
            setPaySuccess(false)
          }, 5000)
        }

        // if (url) {
        //   setFailMessage('')
        // }
      })
    }
  }, [iframe])

  // Async function for checking the status of a Stripe payment
  /**
   * Check the status of a Stripe payment using the provided URL.
   *
   * @async
   * @function
   * @param {string} url - The URL containing payment information.
   * @returns {Promise<void>} - A Promise that resolves once the payment status is checked.
   */
  async function checkStripePayment(url) {
    // Regular expression to extract query parameters from the provided URL
    var regex = /[?&]([^=#]+)=([^&#]*)/g,
      params = {},
      match
    // Loop through the URL and extract query parameters
    while (!isEmpty(url) && (match = regex.exec(url))) {
      params[match[1]] = match[2]
    }
    // Retrieve the Stripe secret key from the extracted parameters
    const stripeSecretKey = params?.key

    // Make a GET request to the Stripe API to retrieve payment intent information
    fetch(
      `https://api.stripe.com/v1/payment_intents/${params?.payment_intent}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${stripeSecretKey}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        // Check if the payment status is "succeeded"
        if (data?.status == 'succeeded') {
          // Set payment success status to "success"
          setPaySuccess('success')
          // Call the onPaymentDone function
          onPaymentDone()
        } else if (data?.status == Strings.requires_action) {
          setPaySuccess(Strings.requires_action)
          onPaymentDone()
        } else if (data?.status == 'processing') {
          setPaySuccess('wait')
          onPaymentDone()
        } else if (data?.status === Strings.requires_payment_method) {
          setPaySuccess('fail')
          setFailMessage(data?.last_payment_error?.message ?? 'Payment Failed!')
          setTimeout(() => {
            setPaySuccess(false)
          }, 5000)
        } else {
          // If payment status is not "succeeded," set payment success status to "fail"
          setPaySuccess('fail')
          // Set a timeout to reset payment success status after 5 seconds
          setTimeout(() => {
            setPaySuccess(false)
          }, 5000)
        }
        // Handle the response data here
      })
      .catch((error) => {
        // Handle any errors here
      })
  }

  // Asynchronous function to obtain an access token
  /**
   * Asynchronously retrieves an access token from the server for the application.
   *
   * @async
   * @function
   * @returns {Promise<void>} - A Promise that resolves once the access token is obtained.
   */
  async function getAccessToken() {
    // Step 1: Set the page loader to indicate that loading is in progress
    setPageLoader(true)

    // Step 2: Prepare data to be sent in the API request
    const data = {
      app_token: paymentData?.app_token, // Retrieve app token from paymentData
      countryCode: paymentData?.country_code, // Retrieve country code from paymentData
    }

    try {
      // Step 3: Make an asynchronous API request to the login endpoint
      const response = await getApiDataProgressPayment(
        `${liveUrl}login`, // URL for the login endpoint
        'POST', // HTTP method (POST)
        data, // Data to be sent in the request body
        chargeData?.auth_token // Authorization token from chargeData (optional chaining used)
      )
      // Step 4: Check if the API response indicates an error (status is false)
      if (response?.status == false) {
        // Step 5: If an error occurred, set the page loader to false
        setPageLoader(false)
      } else {
        // Step 6: If the API response is successful, proceed to handle the data

        // Step 7: Check if appCharges is not empty, then apply charges
        if (isForUpdatePayDetails) {
          updateSubscriptionDetails(response?.data?.auth_token)
        }
        !isEmpty(appCharges)
          ? chargesApply(
              appCharges,
              response?.data?.auth_token,
              response?.data?.userData?.portal_mode
            )
          : // Step 8: If appCharges is empty, get app charges
            getAppCharges(
              response?.data?.auth_token,
              response?.data?.userData?.id,
              response?.data?.userData?.portal_mode
            )
      }
    } catch (error) {
      // Step 9: Handle any errors that occurred during the API request

      // Step 10: Set the page loader to false since an error occurred
      setPageLoader(false)
      console.log('error:', error)
    }
  }

  //update Subscription Details

  async function updateSubscriptionDetails(token) {
    let apiUrl = ``
    switch (paymentData?.updateSubscriptionType) {
      case Strings.pause:
        apiUrl = `${liveUrl}pause-subscription-payment`
        break
      case Strings.resume:
        apiUrl = `${liveUrl}resume-subscription-payment`
        break
      case Strings.cancel:
        apiUrl = `${liveUrl}cancel-subscription-payment`
        break
      default:
        break
    }
    fetch(apiUrl, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        order_code: paymentData?.recurrenceObj?.order_code,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.status) {
          setPageLoader(false)
          setPaySuccess(Strings?.updatePayMethod)
          setFailMessage(data?.message)
          onPaymentDone()
        } else {
          setPageLoader(false)
          setPaySuccess(
            `${capitalizeWordFirstLetter(
              paymentData?.updateSubscriptionType
            )} Failed`
          )
          setFailMessage(data?.message ?? 'Something went wrong!')
        }
        // Handle the response data here
      })
      .catch((error) => {
        // Handle any errors here
        setPageLoader(false)
      })
  }

  //for razorpay subscription

  async function saveOrder(paymentMethod, chargeData) {
    const gatewayFeeType = noCharge
      ? 'inclusive'
      : chargeData?.isPaymentGateWay
      ? 'exclusive'
      : 'inclusive'
    setPaySuccess('loading')
    const payObj = paymentMethod?.[0]
    try {
      const unit_amount = payObj?.charge_object?.charges_obj?.final_amount

      let amountValue = paymentData?.payThroughEMI
        ? chargeData?.withChargeAmount /
          paymentData?.recurrenceObj?.interval_count
        : chargeData?.withChargeAmount

      let finalAmountValue = paymentData?.payThroughEMI
        ? unit_amount / paymentData?.recurrenceObj?.interval_count
        : unit_amount

      const payableAmount =
        gatewayFeeType === 'exclusive' ? finalAmountValue : amountValue
      let data = {
        name: paymentData?.name,
        amount: amountValue,
        //amount will be split in interval_count if payment is through EMI
        final_amount: finalAmountValue,
        app_token: paymentData?.app_token,
        country_id: payObj?.country_id,
        currency: paymentData?.currency,
        mode: chargeData?.mode,
        payment_method_id: payObj?.payment_method_id,
        payment_sub_method_id: payObj?.payment_sub_method_id,
        transaction_code: paymentData?.transaction_code,
        gateway_code: payObj?.charge_object?.gateway_code,
        gateway_id: payObj?.gateway_id,
        payment_gateway_fee: gatewayFeeType,
        email: paymentData?.email,
        base_amount: paymentData?.amount,
        charge_id: payObj?.charge_object?.charges_obj?.id,
        platform: 'app',
        charges_json: JSON.stringify(chargeData?.mainChargeData),
        mobile: paymentData?.mobile,
        is_subscription: isRecurring,
      }

      if (isRecurring) {
        let details = paymentData?.recurrenceObj
        let intervalType = details?.intervalType
        data.recurring_details = JSON.stringify({
          interval: intervalType,
          interval_count: checkNull(details?.interval_count)
            ? 1
            : details?.interval_count,
        })
        // data.start_date = moment(
        //   checkNull(details?.start_at) ? new Date() : details?.start_at
        // )
        //   .add(1, 'hour')
        //   .unix()
        data.iterations =
          checkNull(details?.iterations) || details?.iterations <= 0
            ? intervalType === 'monthly'
              ? 360
              : intervalType === 'yearly'
              ? 30
              : intervalType === 'weekly'
              ? 1565
              : 0
            : details?.iterations
      }
      const response = await getApiDataProgressPayment(
        `${liveUrl}save-order`,
        'POST',
        JSON.stringify(data),
        chargeData?.auth_token
      )
      if (response?.status == false) {
        setPaySuccess('fail')
        setFailMessage(response?.message)
        setTimeout(() => {
          setPaySuccess(false)
        }, 5000)
      } else if (isFromRazorpay && !isRecurring) {
        paymentApi(payObj, response?.code, chargeData, payableAmount)
      } else {
        subscriptionApi(
          1,
          response?.code,
          paymentMethod,
          chargeData,
          payableAmount
        )
      }
    } catch (error) {
      console.log('error:', error)
      setPaySuccess('fail', 'Authentication failed')
      setTimeout(() => {
        setPaySuccess(false)
      }, 5000)
    }
  }

  async function paymentApi(data, code, chargeData, payableAmount) {
    let final_amount_pay = data?.charge_object?.charges_obj?.final_amount
    let final_data = {
      amount: {
        amount: paymentData?.amount,
        final_amount: final_amount_pay,
      },
    }

    // Encrypt
    let ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(final_data),
      '470cb677d807b1e0017c50b'
    ).toString()

    const Ddata = {
      data: ciphertext,
      order_code: code,
      is_new: 1,
      redirect_url: currentDomain?.origin,
    }

    try {
      const response = await getApiDataProgressPayment(
        `${liveUrl}custom-checkout`,
        'POST',
        JSON.stringify(Ddata),
        chargeData?.auth_token
      )
      if (isUndefined(response) || response?.status === false) {
        setPaySuccess('fail')
        setFailMessage(response?.message?.toString())
        setTimeout(() => {
          setPaySuccess(false)
        }, 5000)
      } else {
        if (!checkNull(response?.order_id)) {
          loadRazorpay(payableAmount, response?.order_id)
        } else {
          setPaySuccess('fail')
          setTimeout(() => {
            setPaySuccess(false)
          }, 5000)
        }
      }
    } catch (error) {
      setPaySuccess('fail')
      console.log('error:', error)
    }
  }

  async function subscriptionApi(
    isNew,
    code,
    paymentMethod,
    chargeData,
    payableAmount
  ) {
    const payObj = paymentMethod?.[0]
    let final_pay_amount = payObj?.charge_object?.charges_obj?.final_amount
    let final_data = {
      amount: {
        amount: paymentData?.amount,
        final_amount: final_pay_amount,
      },
    }

    // Encrypt
    let ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(final_data),
      '470cb677d807b1e0017c50b'
    ).toString()
    const Ddata = {
      data: ciphertext,
      order_code: code,
      is_new: isNew,
      remember_me: 0,
      hostName: paymentData.hostName ?? 'aauti-pay',
      type: 'custom_subscription',
      redirect_url: currentDomain?.origin,
    }

    try {
      const response = await getApiDataProgressPayment(
        `${liveUrl}schedule-subscription-payment`,
        'POST',
        JSON.stringify(Ddata),
        chargeData?.auth_token
      )
      if (isUndefined(response) || response?.status === false) {
        setPaySuccess('fail')
        setFailMessage(response?.message?.toString())
        setTimeout(() => {
          setPaySuccess(false)
        }, 5000)
      } else {
        if (!isEmpty(response?.data?.redirect_url)) {
          loadRazorpay(payableAmount, response?.data?.subscription_id)
        } else {
          setPaySuccess('fail')
          setTimeout(() => {
            setPaySuccess(false)
          }, 5000)
        }
      }
    } catch (error) {
      console.log('📌 ⏩ file: index.js:227 ⏩ paymentApi ⏩ error:', error)
    }
  }

  // Function to calculate and process charge-related data
  /**
   * Applies charges to the payment data, calculates amounts, and updates the charge data state.
   *
   * @function
   * @param {Array} mainChargeData - An array containing main charge data.
   * @param {string} token - The authentication token.
   * @param {string} mode - The mode of the charge (e.g., "test" or "production").
   * @returns {void}
   */
  const chargesApply = (mainChargeData, token, mode) => {
    // Initialize variables to store percentage sums for exclusive and inclusive charges
    let sum_per = 0
    let sum_inc_per = 0

    // Filter exclusive charges from mainChargeData
    const filExc = isArray(mainChargeData)
      ? mainChargeData?.filter((V) => V.type === 'exclusive')
      : []

    // Filter inclusive charges from mainChargeData
    const filInc = isArray(mainChargeData)
      ? mainChargeData?.filter((V) => V.type === 'inclusive')
      : []

    // Calculate sum of percentages for exclusive charges excluding "payment_gateway_fee"
    isArray(filExc) &&
      !isEmpty(filExc) &&
      filExc.forEach((v) => {
        if (v.slug !== 'payment_gateway_fee') {
          sum_per = sum_per + v.value
        }
      })
    // Calculate the amount to add based on the sum of exclusive percentages
    const amountToAdd = (sum_per * paymentData?.amount) / 100

    // Calculate sum of percentages for inclusive charges excluding "payment_gateway_fee"
    isArray(filInc) &&
      !isEmpty(filInc) &&
      filInc.forEach((v) => {
        if (v.slug !== 'payment_gateway_fee') {
          sum_inc_per = sum_inc_per + v.value
        }
      })
    const amountToMin = (sum_inc_per * paymentData?.amount) / 100

    const isPaymentGateWay =
      isArray(filExc) &&
      !isEmpty(filExc) &&
      filExc.find((v) => v.slug === 'payment_gateway_fee')

    // Construct chargeData1 object with calculated values
    const chargeData1 = {
      isPaymentGateWay: !isEmpty(isPaymentGateWay),
      exclusive_Data: filExc,
      withChargeAmount: paymentData?.amount + amountToAdd,
      withoutChargeAmount: paymentData?.amount - amountToMin,
      auth_token: token,
      mainChargeData: mainChargeData,
      mode: mode,
      amountToAdd: amountToAdd,
    }
    // Set the calculated charge data using the setChargeData function
    setChargeData(chargeData1)
    // Call the getPaymentOption function with the updated token, total amount, and mode
    getPaymentOption(
      token,
      paymentData?.amount + amountToAdd,
      mode,
      chargeData1
    )
  }

  // Asynchronous function to fetch application charges
  /**
   * Fetches application charges from the server, and applies the charges using the chargesApply function.
   *
   * @async
   * @function
   * @param {string} token - The authentication token.
   * @param {string} useID - The user ID.
   * @param {string} mode - The mode of the charge (e.g., "test" or "production").
   * @returns {Promise<void>} A Promise that resolves when the process is complete.
   */
  async function getAppCharges(token, useID, mode) {
    // Make a GET request to the server to fetch application charges
    fetch(`${liveUrl}get-app-charges/${useID}/${paymentData?.country_code}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json()) // Parse the response as JSON
      .then((data) => {
        // Check if the API response indicates success (status is true)
        if (data?.status) {
          // Apply charges using the chargesApply function with the fetched data
          chargesApply(data?.data, token, mode)
        } else {
          // If the response indicates an error, set the page loader to false
          setPageLoader(false)
        }
        // Handle the response data here
      })
      .catch((error) => {
        // Handle any errors here
        setPageLoader(false)
      })
  }

  // Function to fetch payment options
  /**
   * Fetches payment options from the server based on the provided parameters,
   * and updates the payment methods, active index, and page loader state accordingly.
   *
   * @param {string} auth_token - The authentication token.
   * @param {number} amountToAdd - The amount to add (considering additional charges).
   * @param {string} mode - The mode of the charge (e.g., "test" or "production").
   * @returns {void}
   */
  const getPaymentOption = (auth_token, amountToAdd, mode, chargeData1) => {
    try {
      // Make a GET request to the server to fetch payment options
      fetch(
        `${liveUrl}payment-options/${
          paymentData.country_code
        }?method=${''}&mode=${mode}&amount=${
          noCharge ? paymentData?.amount : amountToAdd
        }&currency=${paymentData?.currency}&reccurance=${isRecurring}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${auth_token}`,
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      )
        .then((response) => response.json())
        .then((response) => {
          // Check if the API response indicates success (status is true)
          if (response?.status) {
            // Set payment methods using the setPaymentMethod function
            setPaymentMethod(response?.data)
            // Set the active index based on the minimum index from the response
            setActiveIndex(response?.minimum_index)
            // Set the page loader to false since the operation is complete
            setPageLoader(false)
            if (isRecurring && paymentData?.updateSubscription) {
              // paymentData?.updateSubscription
              //   ?
              changePayMethod()
              // : paymentData?.country_code === 'IN' &&
              //   saveOrder(response?.data, chargeData1)
            }
          } else {
            // Handle cases where the response indicates an error

            // Check for specific unauthorized error
            if (
              response?.statusCode === 401 &&
              response?.message ===
                'Unauthorized. Access token is missing or invalid.'
            ) {
            }
            // Set the page loader to false since an error occurred
            setPageLoader(false)
          }
        })
        .catch((err) => {
          // Handle any errors that occurred during the API request
          console.log(err.message)
          // Set the page loader to false since an error occurred
          setPageLoader(false)
        })
    } catch (error) {
      setPageLoader(false)
      console.log('ERRRRR', error)
    }
  }

  const checkPaymentProgress = async (code, sendId = false) => {
    return fetch(
      `${liveUrl}pay-response/${code}/${paymentData?.transaction_code}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${chargeData?.auth_token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        if (sendId) {
          return response?.data
        } else {
          setAchData((prev) => ({ ...prev, trans_code: '' }))
          if (response?.data?.status == 'success') {
            setPaySuccess('success')
            onPaymentDone()
          } else if (response?.data?.status == 'processing') {
            setPaySuccess('wait')
            setFailMessage(response?.data?.message)
            onPaymentDone()
            setTimeout(() => {
              setPaySuccess(false)
            }, 5000)
          } else if (response?.data?.status == 'failed') {
            setPaySuccess('fail')
            setFailMessage(response?.data?.message)
            setTimeout(() => {
              setPaySuccess(false)
            }, 5000)
          } else if (response?.data?.status == Strings.requires_action) {
            setPaySuccess(Strings.requires_action)
            onPaymentDone()
          } else if (response?.data?.status == 'initialized') {
            setPaySuccess('fail')
            setFailMessage('Payment Cancelled')
            setTimeout(() => {
              setPaySuccess(false)
            }, 5000)
          } else if (response?.data?.status == 'subscription authenticated') {
            setPaySuccess(Strings.subscriptionSuccess)
            onPaymentDone()
          }
          //subscription created
          else if (response?.data?.status == 'subscription created') {
            setPaySuccess('Subscription Pending')
            setFailMessage(
              capitalizeWordFirstLetter(
                'Please check mobile or email used during the subscription for link and add payment method to start your subscription ,if added ignore this.'
              )
            )
            onPaymentDone()
          } else {
            setPaySuccess(
              response?.data?.status !== undefined &&
                response?.data?.status !== null
                ? response?.data?.status
                : 'fail'
            )
            setFailMessage(
              response?.data?.message !== undefined &&
                response?.data?.message !== null
                ? response?.data?.message
                : 'Payment Cancelled'
            )
            onPaymentDone()
          }
        }
        // Handle the response data here
      })
      .catch((error) => {
        // Handle any errors here
      })
  }

  let popup

  const handleAch = () => {
    popup = window?.open(
      achData.redirect_ach_url,
      '_blank',
      'width=600,height=600'
    )
    setAchData((prev) => ({ ...prev, redirect_ach_url: '' }))
    const timeout = () => {
      let timeOutInt = setTimeout(async () => {
        await checkPaymentProgress(achData?.trans_code)
      }, 10000)
      return () => clearTimeout(timeOutInt)
    }
    const interval = setInterval(() => {
      if (popup && popup.closed) {
        timeout()
        clearInterval(interval)
      }
    }, 2000)
    return () => {
      clearInterval(interval)
    }
  }

  const subType = paymentData?.updateSubscriptionType

  const UpdateType =
    subType === Strings.cancel
      ? 'Cancel Subscription'
      : subType === Strings.resume
      ? 'Resume Subscription'
      : subType === Strings.pause
      ? 'Pause Subscription'
      : Strings.updateMethod

  const isUpdatePayMethod = paymentData?.updateSubscription
    ? paymentData?.updateSubscriptionType === Strings.paymentMethod &&
      paymentData?.country_code !== 'IN'
      ? true
      : false
    : false

  const finalAmount = noCharge
    ? paymentData?.amount || 0
    : // : chargeData?.isPaymentGateWay
      // ? cardBrandSelect?.charge_object?.charges_obj?.final_amount ||0
      chargeData?.withChargeAmount

  const renderLoader = () => {
    return (
      <div
        style={{
          display: 'flex',
          height: '100vh',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            justifyContent: 'space-around',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <CircularProgress size={30} style={{ color: primaryColor }} />
        </div>
      </div>
    )
  }

  const renderModalView = () => {
    return (
      <>
        {paymentMethod?.length > 0 ? (
          <PricingBreakdown
            finalAmount={finalAmount}
            paymentData={paymentData}
            chargeData={chargeData}
            primaryColor={primaryColor}
            linearColorsName={linearColorsName}
            isGradientButton={isGradientButton}
            noCharge={noCharge}
            changeBtnText={changeBtnText}
            onClick={() => {
              saveOrder(paymentMethod, chargeData)
            }}
          />
        ) : (
          renderLoader()
        )}
      </>
    )
  }

  return (
    <Elements stripe={stripePromise}>
      {showHeader && (
        <div
          style={{
            padding: 10,
            backgroundColor: primaryColor,
            borderRadius: 4,
          }}
        >
          <Typography
            style={{ color: '#fff', fontWeight: 'bold', fontSize: 20 }}
          >
            {paymentData?.updateSubscription
              ? UpdateType
              : isFromRazorpay && isRecurring
              ? 'Create Subscription'
              : 'Payment Options'}
          </Typography>
        </div>
      )}

      <Grid
        container
        style={{
          padding: showHeader ? (lg ? '10px 10px' : '20px 20px') : 'unset',
        }}
      >
        {isRecurring && !isUpdatePayMethod && pageLoade && renderLoader()}
        {paySuccess ? (
          <div
            style={{
              display: 'flex',
              height: '100vh',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <PaymentStatus
              primaryColor={primaryColor}
              status={paySuccess}
              message={failMessage}
              sm={sm}
            />
          </div>
        ) : (isFromRazorpay ||
            ((paymentData?.payThroughRecurrence ||
              paymentData?.payThroughEMI) &&
              isFromRazorpay)) &&
          !paymentData?.updateSubscription ? (
          renderModalView()
        ) : isForUpdatePayDetails || isFromRazorpay ? null : (
          <>
            <Grid item xs={12} lg={2} style={{}}>
              <Paper
                elevation={2}
                style={{
                  borderRadius: 4,
                  borderWidth: 1,
                  height: 'auto',
                  width: '100%',
                }}
              >
                <Tabs
                  value={paymentSelectedType}
                  orientation='vertical'
                  scrollButtons={false}
                  onChange={(event, newValue) => {
                    setPaymentSelectedType(newValue)
                  }}
                  sx={{
                    '& .MuiTabs-indicator': {
                      backgroundColor: primaryColor, // Change the color of the indicator
                      width: '6px', // Change the width of the indicator
                      borderTopRightRadius: 4,
                      borderBottomRightRadius: 4,
                      left: 0,
                      top: '50%',
                    },
                  }}
                >
                  {!isEmpty(isOther) && (
                    <Tab
                      sx={{
                        '&.MuiButtonBase-root': {
                          padding: '0px 0px 0px 16px',
                          marginTop: '10px !important',
                          maxWidth: 'unset',
                          alignItems: 'flex-start',
                        },
                        '&.Mui-selected': {
                          color: primaryColor,
                          backgroundColor: themeColor,
                        },
                      }}
                      label={
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 'bold',
                          }}
                        >
                          Digital Wallets
                        </Typography>
                      }
                      value='digital wallet'
                    />
                  )}
                  {!isEmpty(isCard) && (
                    <Tab
                      sx={{
                        '&.MuiButtonBase-root': {
                          padding: '0px 0px 0px 16px',
                          maxWidth: 'unset',
                          alignItems: 'flex-start',
                        },
                        '&.Mui-selected': {
                          color: primaryColor,
                          backgroundColor: themeColor,
                        },
                      }}
                      label={
                        <Typography
                          style={{ fontSize: 14, fontWeight: 'bold' }}
                        >
                          Credit / Debit card
                        </Typography>
                      }
                      value='card'
                    />
                  )}
                  {!isEmpty(isBank) && (
                    <Tab
                      sx={{
                        '&.MuiButtonBase-root': {
                          padding: '0px 0px 0px 16px',
                          maxWidth: 'unset',
                          marginBottom: '10px !important',
                          alignItems: 'flex-start',
                        },
                        '&.Mui-selected': {
                          color: primaryColor,
                          backgroundColor: themeColor,
                        },
                      }}
                      label={
                        <Typography
                          style={{ fontSize: 14, fontWeight: 'bold' }}
                        >
                          Bank
                        </Typography>
                      }
                      value='bank'
                    />
                  )}
                </Tabs>
              </Paper>
            </Grid>
            <Grid item xs={12} lg={10}>
              {pageLoade ? (
                <div
                  style={{
                    display: 'flex',
                    height: '200px',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <CircularProgress style={{ color: primaryColor }} />
                </div>
              ) : isEmpty(paymentMethod) ? (
                <div
                  style={{
                    display: 'flex',
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography style={{ fontSize: 20, fontWeight: 'bold' }}>
                    No Data
                  </Typography>
                </div>
              ) : (
                <CardDetails
                  {...props}
                  isGradientButton={isGradientButton}
                  linearColorsName={linearColorsName}
                  onPaymentDone={onPaymentDone}
                  paymentMethod={paymentMethod}
                  paymentData={paymentData}
                  liveUrl={liveUrl}
                  activeIndex={activeIndex}
                  noCharge={noCharge}
                  primaryColor={primaryColor}
                  chargeData={chargeData}
                  setPaySuccess={(type, message, achDetails = {}) => {
                    setPaySuccess(type)
                    setFailMessage(message)
                    setAchData((prev) => ({
                      ...prev,
                      redirect_ach_url: achDetails?.redirect_url,
                      trans_code: achDetails?.code,
                    }))
                  }}
                  paymentSelectedType={paymentSelectedType}
                  changeBtnText={changeBtnText}
                />
              )}
            </Grid>
          </>
        )}
      </Grid>
      <div
        style={{
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Modal
          open={achData?.redirect_ach_url?.length > 0}
          onClose={() => console.log('iam called')}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Grid
            item
            style={{
              backgroundColor: 'white',
              borderRadius: 4,
              display: 'flex',
              flexDirection: 'column',
              maxWidth: 650,
              maxHeight: 650,
              minHeight: 370,
              minWidth: 650,
              backgroundColor: 'white',
              padding: 10,
            }}
          >
            <Box
              flex={1}
              flexGrow={1}
              display={'flex'}
              flexDirection='column'
              alignItems='center'
              justifyContent='center'
            >
              <Grid alignSelf={'center'} justifySelf={'center'}>
                <Button
                  style={{
                    padding: 14,
                    border: 'none',
                    fontWeight: 600,
                    color: 'white',
                    borderRadius: 5,
                    background: '#0a8fdc',
                  }}
                  onClick={handleAch}
                >
                  <Typography fontSize={12} fontWeight={'bold'}>
                    {[Strings.startSub, Strings.updateMethod]?.includes(
                      achData?.payType
                    )
                      ? achData?.payType
                      : 'Continue to ACH'}
                  </Typography>
                </Button>
              </Grid>
            </Box>
            <Grid
              display={'flex'}
              flexDirection={'row'}
              justifySelf={'flex-end'}
              alignSelf={'center'}
            >
              <Grid
                display='flex'
                justifyContent='center'
                alignItems='center'
                bottom={10}
              >
                <Typography fontSize={16}>Powered by&nbsp;</Typography>
                <Typography
                  fontWeight={'bold'}
                  fontSize={16}
                  className={styles.gradientText}
                >
                  AautiPay
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
      </div>
    </Elements>
  )
}

export default PaymentAggregator
